
/**
 *  Calculate footer height and adjust elements, making footer nicely placed
 *  on bottom of page.
 */
(function() {
    var that = this;
    this.calculateFooter = function() {
        var h = $('.component-footer').outerHeight();
        $('.wrap .pull-footer').css({
            'margin-bottom': -h
        });
        $('.wrap .push-footer').css({
            'height': h
        });
    };
    $(window).resize(function() {
        that.calculateFooter();
    });
    setTimeout(function() {
        that.calculateFooter();
    }, 250);
    
    $('[data-toggle="tooltip"]').tooltip()

})();
